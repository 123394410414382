import {SHOW_MESSAGE} from '../reducers/messagesReducer';
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../util/loadingUtil'
import { URL } from '../util/ambienteActions';
import {CADASTAR_USUARIO_PIX} from '../reducers/minhaAgenciaReducer';

export const CADASTAR_USUARIO = '@usuarioActions/CADASTAR_USUARIO';
export const RECUPERAR_OPCOES_PAGAMENTO = '@usuarioActions/APLICAR_CUPOM_DESCONTO';
export const RECUPERAR_TIPO_PLANO = '@usuarioActions/RECUPERAR_TIPO_PLANO';
export const BUSCAR_INFORMACOES_CARTAO = '@usuarioActions/BUSCAR_INFORMACOES_CARTAO';
export const CHAVE_PUBLICA_PAG = '@usuarioActions/CHAVE_PUBLICA_PAG';


export const showMessage = message => ({
  type: SHOW_MESSAGE,
  payload: {
    message
  }
});

export function cadastrarUsuario (usuario) {
  const url = `${URL}/minha-agencia/public/cadastro-minha-agencia`;
  return dispatch => axios.post(url,usuario , SLOW_REQUEST_CONFIG).then(
      response => dispatch({
        type: CADASTAR_USUARIO,
        payload: response
      })
  );
}

export function cadastrarUsuarioPagamentoPix (usuario) {
    const url = `${URL}/minha-agencia/public/cadastro-minha-agencia-qrcode`;
    return dispatch => axios.post(url,usuario , SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: CADASTAR_USUARIO_PIX,
            payload: response
        })
    );
}


export function recuperarOpcoesPagamento(tipoPlano, bandeira, binCartaoCredito) {
    const url = `${URL}/minha-agencia/public/opcoes-pagamento?tipoPlano=${tipoPlano}&bandeira=${bandeira}&binCartaoCredito=${binCartaoCredito}`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: RECUPERAR_OPCOES_PAGAMENTO,
            payload: response.data
        })
    )
}

export function recuperarTiposPlano(){
    const url = `${URL}/minha-agencia/public/tipo-plano`;
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: recuperarTiposPlano,
            payload: response.data
        })
    )
}
export const buscarInformacoesCartao = url => dispatch => axios.get(url, SLOW_REQUEST_CONFIG)
    .then((response) => {
        return dispatch({
            type: BUSCAR_INFORMACOES_CARTAO,
            payload: response,
        })
    })
    .catch(err => dispatch({
        type: BUSCAR_INFORMACOES_CARTAO,
        payload: {err},
    }));


export function recuperarChavePublica() {
    const url = `${URL}/minha-agencia/public/chave-publica`
    return dispatch => axios.get(url, SLOW_REQUEST_CONFIG).then(
        response => dispatch({
            type: CHAVE_PUBLICA_PAG,
            payload: response.data,
        }),
    )
}