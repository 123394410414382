import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import loading from './loadingReducer'
import messages from './messagesReducer';
import minhaAgencia from './minhaAgenciaReducer';
import pagSeguro from './pagSeguroReducer';

const rootReducer = combineReducers({
    form: formReducer,

    loading,
    messages,
    minhaAgencia,
    pagSeguro

});

export default rootReducer
