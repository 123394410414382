import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {connect} from 'react-redux'
// CORE UI
import '../node_modules/jquery/dist/jquery'
import '../node_modules/popper.js/dist/umd/popper.min'
import '../node_modules/bootstrap/dist/js/bootstrap.min'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/react-s-alert/dist/s-alert-default.css';

import Loading from './componentes/inicio/Loading'
import AppRoutes from './routes/AppRoutes';
import Portal from '@material-ui/core/Portal'
import MessagesBar from './componentes/mensagem/MessagesBar';
import Modal from './componentes/mensagem/MessageModal';
import BarraNavegacao from './componentes/layout/BarraNavegacao';
import Footer from './componentes/layout/Footer'
import Cookies from './componentes/Cookies';
import WhatsApp from './componentes/WhatsApp'
import PagSeguroLoader from './componentes/PagSeguroLoader';


import('./estilo/comum/css/default.css');
import('./estilo/comum/css/template.css');

export const App = () => {
    return <BrowserRouter>
        <React.Fragment>           
            <Loading/>
            <BarraNavegacao/>
            <AppRoutes/>
            <Footer/>
            <Portal>
            <WhatsApp/>
            <Cookies/>
                <MessagesBar/>
                <Modal/>
            </Portal>
            <PagSeguroLoader />
        </React.Fragment>
    </BrowserRouter>
};

export default connect()(App)
